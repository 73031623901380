// src/features/user/userSlice.js

import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  auth,
  functions,
  googleProvider,
  appleProvider,
} from "../../app/firebase";
import {
  signInWithEmailAndPassword,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithCustomToken,
  signInWithCredential,
  OAuthProvider,
  UserCredential,
} from "firebase/auth";
import { getDocs, query, where, collection } from "firebase/firestore";
import { db } from "../../app/firebase";
import { clearUnsubscribeFunctions } from "../../utils/unsubscribeManager";
import { AppDispatch } from "../../app/store";
import { httpsCallable } from "firebase/functions";

interface ProviderData {
  providerId: string;
  uid: string;
  displayName: string | null;
  email: string | null;
  phoneNumber: string | null;
  photoURL: string | null;
}

interface User {
  uid: string | undefined;
  docId: string | undefined;
  email: string | undefined;
  emailVerified: boolean | undefined;
  isAnonymous: boolean | undefined;
  providerData: ProviderData[];
  admin: boolean | undefined;
}

interface VerifyTokenResponse {
  customToken: string;
}

interface UserState {
  currentUser: User | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: UserState = {
  currentUser: null,
  isLoading: false,
  error: null,
};

export const login =
  (email: string, password: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(setLoading(true));
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      await dispatch(processUserCredential(userCredential));
    } catch (error: unknown) {
      dispatch(handleError(error));
      dispatch(setLoading(false));
    }
  };

export const signInWithGoogle = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading(true));
    const result = await signInWithPopup(auth, googleProvider);
    if (!result || !result.user) {
      throw new Error("No user returned from Google sign-in");
    }
    await dispatch(processUserCredential(result));
  } catch (error: unknown) {
    dispatch(handleError(error));
    dispatch(setLoading(false));
  }
};

// New Action for Apple Sign-In
export const signInWithApple = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading(true));
    const result = await signInWithPopup(auth, appleProvider);
    if (!result || !result.user) {
      throw new Error("No user returned from Apple sign-in");
    }
    await dispatch(processUserCredential(result));
  } catch (error: unknown) {
    dispatch(handleError(error));
    dispatch(setLoading(false));
  }
};

export const authenticateWithToken = createAsyncThunk(
  "user/authenticateWithToken",
  async (idToken: string, { rejectWithValue, dispatch }) => {
    try {
      // Call the cloud function to verify the token and get a custom token
      const verifyToken = httpsCallable<{ token: string }, VerifyTokenResponse>(
        functions,
        "verifyToken"
      );
      const response = await verifyToken({ token: idToken });
      const customToken = response.data.customToken; // Assuming the cloud function returns a custom token

      // Step 2: Sign in with the custom token
      const userCredential = await signInWithCustomToken(auth, customToken);

      // Step 3: Process the user credential as we already do
      const userData = await dispatch(processUserCredential(userCredential));

      return userData;
    } catch (error) {
      return rejectWithValue(
        error instanceof Error ? error.message : "Unknown error occurred"
      );
    }
  }
);

export const processUserCredential = createAsyncThunk(
  "user/processUserCredential",
  async (userCredential: UserCredential, { rejectWithValue }) => {
    try {
      const { uid, email, emailVerified, isAnonymous, providerData } =
        userCredential.user;

      const userQuery = query(collection(db, "users"), where("uid", "==", uid));
      const querySnapshot = await getDocs(userQuery);

      if (!querySnapshot.empty) {
        const docId = querySnapshot.docs[0].id;
        const admin = querySnapshot.docs[0].data().admin || false;

        return {
          uid,
          email: email || "",
          emailVerified,
          isAnonymous,
          providerData,
          docId,
          admin,
        } as User;
      } else {
        throw new Error("User document not found in Firestore");
      }
    } catch (error: unknown) {
      return rejectWithValue(
        error instanceof Error ? error.message : "Unknown error occurred"
      );
    }
  }
);

export const handleError = (error: unknown) => (dispatch: AppDispatch) => {
  if (error instanceof Error) {
    dispatch(setError(error.message));
  } else {
    dispatch(setError("Unknown error occurred"));
  }
};

// Handle logout
export const logout = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading(true));
    clearUnsubscribeFunctions(); // Unsubscribe all active subscriptions
    await signOut(auth);
    dispatch(setCurrentUser(null));
    dispatch(setLoading(false));
  } catch (error: unknown) {
    dispatch(handleError(error));
    dispatch(setLoading(false));
  }
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authenticateWithToken.fulfilled, (state, action) => {
        const user = action.payload as unknown as User;
        state.currentUser = user;
        state.isLoading = false;
      })
      .addCase(authenticateWithToken.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(processUserCredential.fulfilled, (state, action) => {
        const user = action.payload as User;
        state.currentUser = user;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(processUserCredential.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setCurrentUser, setLoading, setError, clearError } =
  userSlice.actions;

export default userSlice.reducer;

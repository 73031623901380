import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "./storeHooks";
import { AppState } from "../app/store";
import {
  listenLatestCapture,
  setLeftImage,
  setRightImage,
  setRoiImage,
} from "../features/captures/capturesSlice";
import {
  addUnsubscribeFunction,
  removeUnsubscribeFunction,
} from "../utils/unsubscribeManager";

export const useLastCaptureSubscription = () => {
  const dispatch = useAppDispatch();
  const unsubscribeRef = useRef<null | (() => void)>(null);

  const user = useAppSelector((state: AppState) => state.user.currentUser);
  const lastCapture = useAppSelector(
    (state: AppState) => state.captures.lastCapture
  );

  useEffect(() => {
    if (user && typeof user.docId === "string") {
      dispatch(listenLatestCapture(user.docId)).then((unsub: any) => {
        unsubscribeRef.current = unsub;
        addUnsubscribeFunction(user.docId!, unsub);
      });
    }

    return () => {
      if (typeof unsubscribeRef.current === "function") {
        unsubscribeRef.current();
        if (user && typeof user.docId === "string") {
          removeUnsubscribeFunction(user.docId!);
        }
      }
    };
  }, [user, dispatch]);

  useEffect(() => {
    const fetchImages = async () => {
      if (lastCapture?.left_vizualization) {
        dispatch(setLeftImage(lastCapture.left_vizualization));
      }

      if (lastCapture?.right_visualization) {
        dispatch(setRightImage(lastCapture.right_visualization));
      }

      if (lastCapture?.roi_visualization) {
        dispatch(setRoiImage(lastCapture.roi_visualization));
      }
    };

    if (lastCapture) {
      fetchImages();
    }
  }, [lastCapture, dispatch]);

  return () => {
    if (typeof unsubscribeRef.current === "function") {
      unsubscribeRef.current();
      if (user && typeof user.docId === "string") {
        removeUnsubscribeFunction(user.docId!);
      }
    }
  };
};
